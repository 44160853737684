<script lang="ts" setup>
import { LINKS } from '~~/config'

// pick keys from key value pairs
const links = Object.fromEntries(
  Object.entries(LINKS).filter(([key]) =>
    ['paper', 'disclaimer', 'terms', 'docs', 'twitter', 'discord', 'audits'].includes(key)
  )
)
</script>

<template>
  <ul class="flex sm:flex-nowrap sm:justify-start sm:text-left flex-wrap justify-center tb:py-0 py-4 font-medium text-sm">
    <li v-for="(val, key) in links" :key="key" class="my-2">
      <a
        :href="val"
        target="_blank"
        rel="noopener noreferrer"
        class="fv-hide rounded-2.5 whitespace-nowrap text-center sm:text-left !px-4 !pt-1.3 !pb-1.5 dark:hf-bg-gr-blueOxfordMidnightIndigoPurple bgr-border-bppywb-15 b-hf-in text-gray-romanSilver !hf-text-current common-transition"
      >
        {{ $t(`links.${key}`) }}
      </a>
    </li>
  </ul>
</template>
