<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ useScope: 'global' })
</script>

<template>
  <a
    target="_blank"
    href="https://hubble.exchange"
    class="whitespace-nowrap tb:pr-4 py-2 text-gray-romanSilver hf-text-current common-transition flex flex-col text-center xs:inline-block xs:text-left"
    :title="`© ${new Date().getFullYear()} Hubble Exchange ${t('footer.copyright')}`"
  >
    <span>© {{ new Date().getFullYear() }}</span>

    <span class="ml-1.5">{{ t('footer.copyright') }}</span>
  </a>
</template>
